

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/avocat-propriete-intellectuelle-services-scaled.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost14 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Avocat en droit de la propriété intellectuelle : enregistrement de marques de commerce, droits d’auteur et brevets! - Soumissions Avocat"
        description="Protégez vos idées et inventions originales avec un avocat spécialisé en droit de la propriété intellectuelle!"
        image={LeadImage}>
        <h1>Avocat en droit de la propriété intellectuelle : enregistrement de marques de commerce, droits d’auteur et brevets!</h1>

					
					
						<p><strong>Le secteur de la propriété intellectuelle a connu une croissance effrénée depuis la popularisation du web et celle-ci se maintient à ce jour!</strong> Le nombre d’entreprises exposant leurs services, leurs marques et leur personnalité sur la toile a pour effet de générer une clientèle internationale, mais également d’exposer des idées originales au monde entier!</p>
<p><StaticImage alt="avocat propriete intellectuelle services" src="../images/avocat-propriete-intellectuelle-services-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Existe-t-il une façon juridique de protéger vos idées, vos œuvres, vos marques et même vos inventions?</strong> Absolument! Le droit de la propriété intellectuelle prévoit des moyens efficaces de mettre vos idées à l’abri des contrefacteurs! Par l’entremise de l’enregistrement d’une marque de commerce, d’un droit d’auteur ou du dépôt d’un brevet, la loi sait protéger vos droits de propriété intellectuelle.</p>
<p><strong>Soumissions Avocat vous explique comment protéger vos idées et réalisations en utilisant les lois sur la propriété intellectuelle. En prime, nous vous référons gratuitement à un avocat qualifié dans le domaine!</strong></p>
<h2>Tous les services d’avocats en droit de la propriété intellectuelle!</h2>
<p><strong>La propriété intellectuelle est un domaine avec lequel peu d’entrepreneurs sont familiers</strong>. En effet, tous ont déjà entendu les mots « marque de commerce », brevet et droit d’auteur, mais peu savent les identifier ou, du moins, différencier les droits découlant de chacun d’eux.</p>
<p>Pourtant, l’étendue des droits conférés par ces différents droits de propriété intellectuelle varie énormément et il importe de les connaître. Pour ce faire, vous aurez besoin des savants conseils de l’avocat en droit de la propriété intellectuelle. Celui-ci vous accompagnera afin de vous offrir les services suivants!</p>
<p><strong>Recherches en matière de brevets, marques de commerce et noms de domaine. </strong>Quel que soit le type de droit que désiriez protéger, qu’il s’agisse d’une marque de commerce, d’un brevet ou même d’un dessin, l’unicité fait toujours partie des critères déterminants.</p>
<p>C’est pourquoi les avocats mandatés effectueront des recherches préalables afin de s’assurer que votre idée n’a pas déjà été enregistrée. Il s’agit de l’étape des vérifications diligentes!
<strong>
Dépôt des droits de propriété intellectuelle aux fins d’enregistrement. </strong>Une fois les vérifications faites, l’avocat agent de brevet ou de marque de commerce vous accompagne tout au long des démarches visant à déposer votre demande d’enregistrement. Cela vous assure une conformité au niveau de la démarche et améliore donc vos chances d’obtenir l’enregistrement convoité.</p>
<p>Représentation lors de situations litigieuses. L’enregistrement fait peut-être partie des services d’avocat en droit de la propriété intellectuelle, mais le litige aussi! Les violations de droits d’auteur, de marques de commerce et de brevets arrivent fréquemment. Dans de telles circonstances, les services de l’avocat deviennent essentiels afin de protéger votre marque contre les violations.</p>
<p><strong>Maintenant que vous savez que les services d’avocats sont indispensables, voici comment fonctionne la protection des différents droits de propriété intellectuelle au Québec!</strong></p>
<h2>Enregistrer une marque de commerce avec un avocat, par où commencer?</h2>
<p>La marque de commerce est possiblement le droit de propriété intellectuelle le plus ardemment défendu. Avec les efforts, le temps et la créativité qui sont consacrés au développement d’une marque distinctive et propre à une entreprise, ça se comprend. Cela étant dit, quelle est la définition juridique d’une marque de commerce et comment est-elle protégée par la loi?</p>
<p><StaticImage alt="enregistrer marque commerce avocat" src="../images/enregistrer-marque-commerce-avocat.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Une marque de commerce se définit comme </strong>une marque employée de façon à distinguer les produits, services et marchandises vendues ou louées. Autrement dit, il s’agit d’un signe distinctif rattaché aux produits et services d’un commerce donné, ce qui peut d’ailleurs inclure le nom de l’entreprise.</p>
<p><strong>À quelles conditions l’enregistrement d’une marque de commerce sera-t-il concédé?</strong> Au Québec, l’octroi d’une marque de commerce enregistrée est subordonné à deux conditions, soit le caractère distinctif de celle-ci ainsi que son utilisation.</p>
<p>Pour que la marque soit considérée comme ayant un <strong>« caractère distinctif »,</strong> elle doit être rattachée à un produit ou un service, le propriétaire doit utiliser le lien entre le produit et la marque et finalement, ce lien doit permettre la distinction du produit à celui des autres fabricants.</p>
<p>Finalement, une fois le caractère distinctif établi, la marque devra être employée, c’est-à-dire « active » pour que l’enregistrement puisse être conféré. Elle doit donc être utilisée dans la distribution des marchandises ou lors de la prestation des services.</p>
<p><strong>Comment fonctionne la procédure d’enregistrement?</strong> Une fois que vous avez une marque de commerce distinctive et employée entre les mains, vous pourrez soumettre une demande d’enregistrement au Bureau des marques de commerce. Celle-ci sera soumise à un examen du registraire!</p>
<p>L’examen du registraire vise à s’assurer que la marque déposée n’entre pas en conflit avec une marque déjà enregistrée, qu’elle ne crée pas de confusion avec une marque existante et qu’il s’agit bien d’une marque enregistrable. Si le registraire est d’avis que la demande se trouve dans l’une ou l’autre de ces situations, la demande sera rejetée.</p>
<p><strong>Quels droits vous confère l’enregistrement d’une marque de commerce? </strong>Advenant que votre demande d’enregistrement soit conférée, les droits découlant de celle-ci seront le monopole d’exploitation sur les mots, signes et images distinctives protégées et le recours en cas de violation de la marque de commerce.</p>
<p><strong>Une marque non enregistrée vous confère-t-elle des droits? </strong>Oui, mais faites bien attention! Notez que l’existence de la marque de commerce (et les droits qui en découlent n’est pas conditionnelle à l’enregistrement. La marque existe par l’usage, mais l’enregistrement confère plusieurs avantages.</p>
<p>Notamment, une marque enregistrée peut être utilisée au Canada pendant 15 ans et peut être renouvelée durant cette même période. Le plus grand avantage est que l’enregistrement aide à faire facilement la preuve de l’existence de cette même marque de commerce!</p>
<p><strong>Assurez-vous de protéger l’image de votre entreprise en sollicitant un avocat pour l’enregistrement d’une marque de commerce!</strong></p>
<h2>Quels sont les critères pour déposer une demande de brevet avec un avocat?</h2>
<p>La protection des inventions nouvelles est une nécessité économique au Québec! Pour cette raison, les brevets jouissent d’une vaste protection juridique conférée par la Loi sur les brevets. L’article 2 de cette même loi définit le brevet comme toute réalisation, tout procédé, toute machine, fabrication ou composition et tout perfectionnement possédant un caractère <strong>nouveau</strong> et <strong>utile</strong>.</p>
<p>Le dépôt d’une demande au Bureau des brevets est donc conditionnel à ces deux conditions de nouveauté et d’utilité! Les idées ne sont d’ailleurs pas brevetables et elles doivent s’être réalisées pour bénéficier de la protection de la loi. Même si la notion d’invention est définie largement par la Loi sur les brevets, toujours est-il qu’elle doit s’être matérialisée.  <strong>
</strong>
<strong>La matière brevetable doit respecter plusieurs critères pour jouir d’une protection juridique. Pour déterminer si une invention peut bénéficier de la protection du brevet, il faudra passer au travers d’un test développé par les tribunaux canadiens :
</strong>
<strong>-Objet brevetable :</strong> Il faut être en présence soit d’une réalisation, d’un perfectionnement, d’un procédé, d’une matière ou d’un composé nouveau. Si l’objet n’entre dans aucune de ces catégories, la demande de brevet échouera.</p>
<p>–<strong>Nouveauté</strong> : Le critère de la nouveauté réfère simplement au fait qu’une demande ne doit pas avoir été déposée portant sur le même objet pour lequel une protection est demandée.

–<strong>Utilité </strong>: Le critère brevetable de l’utilité fait référence à la capacité de l’objet de fonctionner.  Autrement dit, l’objet doit être en mesure d’accomplir les objectifs promis dans la demande de brevet.</p>
<p>–<strong>Non évident :</strong> L’objet au centre de la demande de brevet ne doit pas apparaître, aux yeux d’une personne s’y connaissant dans le domaine scientifique, comme une invention évidente. Dans le cas contraire, la demande sera rejetée.
</p>
<p><strong>Quels droits obtenez-vous si la demande de brevet est accordée? </strong>L’octroi d’un brevet, en sol canadien, permet d’empêcher l’utilisation, l’exploitation et la vente de l’objet breveté pour une période de 20 ans suivant la date du dépôt. Votre nouvelle invention est donc protégée par la loi et vos recours sont facilités.</p>
<p>Comment l’avocat vous aide-t-il à déposer une demande de brevet? N’obtiens pas un brevet qui veut! Pour qu’une invention soit protégée pendant 20 ans par la Loi sur les brevets, elle doit d’abord passer par plusieurs étapes juridiques et administratives. De plus, contrairement aux autres droits de propriété intellectuelle, seul le brevet ne jouit d’aucune protection sans enregistrement!</p>
<p><strong>Pour maximiser vos chances d’obtenir le brevet convoité, associez-vous aux avocats qualifiés en droit de la propriété intellectuelle.</strong> En s’associant à des agents de brevet compétents, vous serez entouré de tous les professionnels dont vous avez besoin pour déposer une demande conforme et complète!</p>
<h2>Les dessins industriels, comment sont-ils protégés?</h2>
<p>L’enregistrement de dessins industriels est la forme de propriété intellectuelle la moins fréquemment utilisée. Cela n’enlève toutefois rien à son importance, loin de là! En effet, le dessin industriel confère également une protection extensive à ses créateurs, dont une protection contre l’utilisation, le droit de vendre des licences ainsi que la commercialisation du dessin.</p>
<p><StaticImage alt="dessin industriel enregistrement avocat" src="../images/dessin-industriel-enregistrement-avocat.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Qu’est-ce que l’enregistrement de dessins industriels vise à protéger? </strong>Ce sont les caractéristiques visuelles particulières du dessin, sa configuration, ses motifs et autres aspects esthétiques qui jouissent d’une protection de la loi. Seuls ces éléments sont protégés par la Loi sur les dessins industriels.</p>
<p>Les critères à respecter pour enregistrer un dessin industriel. Comme pour toute demande de protection de droit de la propriété intellectuelle, les dessins doivent passer par un test d’originalité afin de se voir octroyer une protection. En matière de dessins, on parlera généralement d’une « étincelle d’originalité », laquelle permet de différencier le dessin des autres.</p>
<p>Seul l’enregistrement confère un droit d’exploitation exclusif du dessin industriel pendant 10 ans! Un dessin industriel ne profite d’aucune protection sans enregistrement. C’est donc dire qu’une demande doit être déposée au Bureau des brevets pour que la protection décennale permettant l’exploitation exclusive ne prenne effet.</p>
<p>Le fait que peu d’entreprises ne prennent la peine d’enregistrer des dessins industriels vous donne une longueur d’avance et un terrain de jeu presque vacant. Tirez profit au maximum de cet avantage en consultant un avocat qui vous aidera à la préparation de votre demande d’enregistrement de dessin industriel.</p>
<h2>Votre œuvre est-elle réellement protégée par le droit d’auteur?</h2>
<p>La propriété littéraire et artistique est protégée par le droit d’auteur. Au même titre que la marque de commerce (et contrairement au brevet), la protection du droit d’auteur n’est pas conditionnelle à son enregistrement. Du moment qu’une œuvre respecte les critères de fixation et d’originalité, elle confèrera une protection à l’auteur.</p>
<p><strong>Quelles sont les œuvres protégées par le droit d’auteur?</strong> Ce droit de propriété intellectuelle inclut les œuvres littéraires, musicales, dramatiques, artistiques et plus encore. Toutefois, sachez que le droit d’auteur ne protège pas les idées artistiques, mais bien l’expression de ces mêmes idées sur un support quelconque. Les idées sont la propriété de tout un chacun!</p>
<p>À quel moment le droit d’auteur devient-il protégé par la loi? Pour profiter de la protection du droit d’auteur, deux critères doivent être satisfaits, soit la fixation de l’œuvre sur un support et l’originalité de l’œuvre.</p>
<p><strong>1) La fixation de l’œuvre sur un support</strong> : La fixation de l’œuvre réfère à sa matérialisation sur un support, quel qu’il soit. C’est cette fixation qui distingue le droit d’auteur d’une simple idée!</p>
<p><strong>2) L’originalité de l’œuvre :</strong> Le seuil d’originalité du droit d’auteur est le plus faible de tous les droits de propriété intellectuelle. La loi n’exige qu’une simple émanation de l’auteur, soit un minimum d’effort provenant de l’auteur pour respecter le critère d’originalité.</p>
<p>Sachant cela, est-il réellement nécessaire d’enregistrer une marque de commerce? Votre droit d’auteur existe même en l’absence d’enregistrement, mais cela ne signifie pas que ce processus est inutile pour autant.</p>
<p>En effet, l’enregistrement vous confère un certificat de droit d’auteur, lequel vous permettra de faire rapidement la preuve de votre droit contre une personne entrant en violation de celui-ci. En cas de litige, un droit d’auteur enregistré vous rendre fière chandelle!</p>
<p>Le droit d’auteur confère une protection contre l’utilisation de l’œuvre pendant toute la vie de l’auteur plus 50 ans après son décès. Il s’agit de la protection la plus généreuse en matière de propriété intellectuelle, puisque votre œuvre n’entrera dans le domaine public que plusieurs dizaines d’années après votre décès.</p>
<p><strong>Après les heures d’efforts consacrées à réaliser une œuvre créative et originale, prenez la peine de la protéger en obtenant les conseils d’un avocat qualifié en propriété intellectuelle.</strong></p>
<h2>Quels sont vos recours légaux si quelqu’un viole vos droits de propriété intellectuelle?</h2>
<p>Évidemment, comme les avocats sont les juristes du « conflit », ils sont parfaitement aptes à vous représenter lorsqu’une personne viole vos droits de propriété intellectuelle, que ce soit en matière de brevet, de marque de commerce ou de droit d’auteur. Tout cela est bien beau en théorie, mais en pratique, quels recours s’ouvrent à vous advenant qu’on empiète sur vos droits?</p>
<p><StaticImage alt="recours violation brevet droit auteur" src="../images/recours-violation-brevet-droit-auteur-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Voici les différents recours que vous pourriez entreprendre avec un avocat!</strong></p>
<p><strong>Le recours en contrefaçon de brevet </strong>(Vise à récupérer les profits générés par l’utilisation illégale de l’objet breveté ou à obtenir des dommages-intérêts.)</p>
<p>Violation du droit d’auteur (L’injonction et le recours en dommages-intérêts)
<strong>
La radiation d’une marque de commerce déposée </strong>(Lorsqu’elle entre en conflit avec votre marque de commerce déjà déposée)</p>
<p>L’action en passing off (Lors d’une confusion entre deux marques de commerce)<strong>

Qu’est-ce que le cybersquatting? </strong>Le cybersquatting (ou cybersquattage) est un phénomène consistant à enregistrer un nom de domaine relié à la marque de commerce d’une entreprise. Bien souvent, le cybersquatteur cherche à tirer profit de cet enregistrement en revendant le nom de domaine à l’entreprise pour une somme importante. Ne cédez pas aux menaces et consultez un avocat avant de faire quoi que ce soit.</p>
<p>Comment choisir le bon recours pour faire respecter vos droits de propriété intellectuelle? Dans plusieurs situations, vous aurez le choix entre plusieurs recours! Le rôle de l’avocat sera évidemment de vous guider vers le recours vous offrant les meilleures chances de succès.</p>
<p><strong>Que ce soit pour empêcher l’utilisation de votre marque de commerce ou pour obtenir compensation suite à l’utilisation frauduleuse d’un objet breveté, les conseils de l’avocat en droit de la propriété intellectuelle sont indispensables!</strong></p>
<h2>Soumissions Avocat trouve votre expert juridique en droit de la propriété intellectuelle!</h2>
<p>Vous désirez protéger vos œuvres et marques originales grâce au pouvoir de la loi? Quelqu’un viole vos droits de propriété intellectuelle et vous souhaitez prendre les moyens légaux pour mettre fin à cette intrusion? Dans un cas comme dans l’autre, les services d’un avocat qualifié en droit de la propriété intellectuelle vous seront essentiels!</p>
<p>Du dépôt d’une marque de commerce jusqu’au litige pour contrefaçon ou passing off, les avocats sont les professionnels dont vous avez absolument besoin en matière de propriété intellectuelle. La survie de vos idées originales dépend de leur assistance, alors faites-leur signe sans tarder!</p>
<p><strong>Soumissions Avocat vous propose de vous mettre en contact avec un avocat pratiquant le droit de la propriété intellectuelle dans votre région, et ce, gratuitement et sans engagement!</strong></p>
<p><strong>Vous n’avez qu’à remplir notre formulaire en bas de page en expliquant vos besoins et Soumissions Avocat se charge de vous référer aux meilleurs avocats dans votre région.</strong></p>
    </SeoPage>
)
export default BlogPost14
  